@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Akshar:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600&display=swap');

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
input {
    box-sizing: border-box;
}
.error {
    color: red;
}

.bg-white {
    background-color: white;
    border-radius: 10px;
    padding: 1.25rem 1.5rem;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.mb-4 {
    margin-bottom: 4rem !important;
}

.center-text {
    text-align: center;
}

.left-align-text {
    text-align: left;
}

#code-snippet {
    text-align: left;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.upper-scraper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $ticket_scraper_blue;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1.2s;
}




.upper-scraper > .label {
    color: white;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 7vw;
    font-family: "Akshar";

}

.underline {
    border-bottom: 8px solid $ticket_scraper_green;

}

.upper-scraper .form {
    background: white;
    border: 3px solid $ticket_scraper_green;
    border-radius: 25px;
    width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5vw;
    padding-top: 1vw;
    margin-top: 1vw;
    margin-bottom: 2vw;
}
.upper-scraper .form .top-layer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5vw;
}
.upper-scraper .form .top-layer .label {
    color: $ticket_scraper_green;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
    font-family: "Akshar";
}

.upper-scraper .form .top-layer .radio-buttons {
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.2vw;
    margin-left: 0.5vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.upper-scraper .form .top-layer .radio-buttons input[type='radio'] {
    accent-color: $ticket_scraper_green;
}

.upper-scraper .form .top-layer .radio-buttons label {
    margin-right: 0.5vw;
    margin-left: 0.25vw;
    color: $ticket_scraper_grey;
}

.upper-scraper .form .top-layer .radio-buttons input[type="radio"]:checked+label {
    color: black;
}

.upper-scraper .form .main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.5vw;
    width: 100%;
}
.upper-scraper .form .main .field {
    flex: 23.5% 0;
    margin-right: 2%;
    font-size: 1.2vw;
}

.upper-scraper .form .main .date {
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
}

.upper-scraper .form .main .error {
    color: red;
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 300;
    margin-top: 0.25vw;
}

#optionalSecondDate {
    margin-right: 0;
}
.upper-scraper .form .main label {
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 400;

}

.upper-scraper .form .main input, .select {
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.2vw;
    border: 2px solid $ticket_scraper_green;
    border-radius: 5px;
    padding: 0.5vw;
    width: 100%;
    background-color: white;
}

.select:hover, .select__control:hover {
    cursor: text;
}




.select__control {
    min-height: unset !important;
}


.select__menu
{
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box
}

.select__menu-list {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
}

.select__option {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
}

.select__option--is-focused {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: $ticket_scraper_light_blue;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
}

.select__option--is-selected {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #2684FF;
    color: hsl(0, 0%, 100%);
    padding: 8px 12px;
    box-sizing: border-box
}
.upper-scraper .form .submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.5vw;
}

.upper-scraper .form .submit button {
    font-family: 'Akshar', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    background-color: $ticket_scraper_blue;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 0.5vw;
    
}

.upper-scraper .form .submit button:hover {
    background-color: $ticket_scraper_darker_blue;
    cursor: pointer;
    -webkit-transition: background-color 0.25s linear;
    -moz-transition: background-color 0.25s linear;
    transition: background-color 0.25s linear;
}

.lower-scraper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1vw;
    padding-left: 3vw;
    padding-right: 3vw;
}

.lower-scraper .instructions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lower-scraper .instructions .instruction {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    color: $ticket_scraper_grey
}

.lower-scraper .highlight {
    color: $ticket_scraper_green;
}

.lower-scraper table {
    width: 100%;
    font-size: 1.2vw;
    margin-top: 1vw;
    font-weight: 400;
    font-family: 'Assistant';
    font-style: normal;
    border-spacing: 0;
    
}



.lower-scraper .table td {
    border-bottom: 3px solid $ticket_scraper_grey;
    padding: 0.5vw 0 0.5vw 0;
}

.lower-scraper .table img {
    height: 1.1vw;
}
.lower-scraper .table th {
    border-bottom: 3px solid $ticket_scraper_blue;
    text-align: left;
    font-size: 1.4vw;
    
}
.lower-scraper .table .sortable {
    cursor: pointer;
}

.lower-scraper .table .sortable:hover {
    color: $ticket_scraper_blue;
    cursor: pointer;
    -webkit-transition: color 0.25s linear;
    -moz-transition: color 0.25s linear;
    transition: color 0.25s linear;
}

.loading-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vw;
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    text-align: center;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-bottom: 1vw;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (max-width: 768px) {
    .upper-scraper > .label {
        font-size: 6vw;
        text-align: center;
        margin-bottom: 4vw;

    }

    .underline {
        border-bottom: 0px solid $ticket_scraper_green;
    }
    .upper-scraper .form {
        padding: 3vw;
        width: 90vw;
    }
    .upper-scraper .form .main .field {
        flex: 100%;
        margin-right: 0;
        font-size: 5vw;
        margin-bottom: 1vw;
    }
    .upper-scraper .form .main input, .select {
        font-size: 5vw;
    }

    .upper-scraper .form .top-layer .label {
        font-size: 5vw;
    }

    .upper-scraper .form .top-layer .radio-buttons label {
        font-size: 5vw;
    }

    

    .upper-scraper .form .submit button {
        font-size: 6vw;
        padding: 1vw;
    }
    .loader {
        margin-bottom: 3vw;
    }
    .loading-container {
        font-size: 6vw;
    }

    .lower-scraper table {
        font-size: 3vw;
    }

    .lower-scraper .table th {
        font-size: 3.45vw;
    }

    .lower-scraper .instructions .instruction {
        font-size: 3vw;
    }

    .lower-scraper .table img {
        height: 3vw;
    }
}