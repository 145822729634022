@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import './variables';
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  /* text-align: center; */
  /* margin-top: 50px; */
  // background-color: #f7f7d0;
  background-color: white;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    text-align: left;
    margin: 10px;
}
